.login_page_custom {
  background-image: radial-gradient(
    circle at 50% 14em,
    #36242a 0%,
    #1d0433 60%,
    #1d0433 100%
  ) !important;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.authorization_form {
  margin-top: 6em;
  gap: 20px;
  display: flex;
  flex-direction: column;
  padding: 1rem 6px 6px 6px;
  border-radius: 4px;
  background: #ffffff;
  height: fit-content;
  align-items: center;
  width: 300px;
}

.input_container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 1em 0 1em;
  width: 100%;
}

.lockIcon {
  color: white;
  background-color: #bfbfbf;
  border-radius: 50%;
  padding: 8px;
  height: 40px !important;
  width: 40px !important;
  margin-bottom: 20px;
}
