.title {
	flex: 1;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.logo {
	height: 50px;
	margin: 10px 0;
	cursor: 'pointer';
}

.spacer {
	flex: 1;
}
